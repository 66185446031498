@use 'reset';
@use 'colors';
@use 'typography';
@use 'layout';
@use 'loader';
@use 'menu';
@use 'services';
@use 'process';
@use 'why';
@use 'about';
@use 'footer';
@use 'curtains';

* {
  box-sizing: border-box;
}

body {
  background: #fff;
  color: var(--color-navy-0000);
  -webkit-font-smoothing: antialiased;
}

a {
  &.textlink {
    color: var(--color-navy-0000);
    transition: all .4s ease;

    &:hover {
      color: var(--color-navy-0300);
    }
  }
}